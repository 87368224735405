import React from 'react'
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TimelineLite, Elastic } from "gsap/all"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "../scss/templates/pagination.scss"

const NavLink = props => {
  if (!props.test) {
    return <AniLink fade duration={1} to={props.url}>{props.text}</AniLink>
  } else {
    return <span>{props.text}</span>
  }
}

class Pagination extends React.Component {
  constructor(props) {
    super(props)
    this.postTween = new TimelineLite({delay: 0.2})
    this.postItems = []
  }
  componentDidMount() {
    this.postTween.staggerTo(this.postItems, 2 , { ease: Elastic.easeOut.config(1, 0.4) , y:0, opacity: 1} , 0.1)
  }
  render() {
    const { pageContext } = this.props
    const { group, index, first, last, pageCount } = pageContext
    const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
    const nextUrl = (index + 1).toString()

    return (
      <Layout location={this.props.location} crumbLabel={`Blog P.${index}`}>
        <SEO
          title="ブログ"
          description="ブログの記事一覧ページです"
          keywords={[`ブログ記事一覧`]}
        />
        <div className="paginationPageWrap">
          <div className="paginationPage">

            <h2 className="pageSectionTitle">
              <span className="pageSectionTitle_l">Blog</span>
              <span className="pageSectionTitle_s">全記事一覧</span>
            </h2>

            <div className="catLink">
              <AniLink fade duration={1} to={`/categories`}>
                <span>Categories</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="62" viewBox="0 0 32 62"><path d="M28.127,2.966a4,4,0,0,1,5.746,0L55.429,25.217A4,4,0,0,1,52.556,32H9.444a4,4,0,0,1-2.873-6.783Z" transform="translate(32) rotate(90)"/></svg>
              </AniLink>
            </div>

            <ul className="paginationBlogList">
              {group.map(({ node }, i) => (
                <li key={node.id} className="paginationBlogList_item" ref={li => this.postItems[i] = li}>
                  <AniLink fade duration={1} to={`/${node.slug}`} className="paginationBlogList_img" >
                    {node.featured_media && (
                      <Img
                        fluid={node.featured_media.localFile.childImageSharp.fluid}
                        alt={node.title}
                      />
                    )}
                  </AniLink>
                  <div className="paginationBlogInfo">
                    <span className="paginationBlogInfoMeta">
                      <span className="paginationBlogInfoMeta_date">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g><g><rect className="cls-1" width="48" height="48" fill="none"/></g><g className="paginationBlogInfoMeta_date-icon"><path className="cls-2" d="M16,10H8a2,2,0,0,0-2,2V40a2,2,0,0,0,2,2H40a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2H38" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" /><line className="cls-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" x1="22" y1="10" x2="32" y2="10"/><line className="cls-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" x1="16" y1="6" x2="16" y2="14"/><line className="cls-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" x1="32" y1="6" x2="32" y2="14"/><line className="cls-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" x1="6" y1="21" x2="34" y2="21"/></g></g></svg>
                        <span className="paginationBlogInfoMeta_date-txt">{node.date}</span>
                      </span>
                      {node.date < node.modified &&
                        <span className="paginationBlogInfoMeta_modified">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g><g><rect className="cls-1" width="48" height="48" fill="none"/></g><g className="paginationBlogInfoMeta_modified-icon"><polyline className="cls-2" points="17.18 15 8.18 15 8.18 6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px"/><path className="cls-2" d="M10.58,12A18,18,0,1,1,6.23,26.88" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px"/></g></g></svg>
                          <span className="paginationBlogInfoMeta_modified-txt">{node.modified}</span>
                        </span>
                      }
                      <AniLink fade duration={1} to={`/categories/${node.categories[0].slug}`} className="paginationBlogInfoMeta_cat">
                        <span>{node.categories[0].name}</span>
                      </AniLink>
                    </span>
                    <AniLink fade duration={1} to={`/${node.slug}`} >
                      <h3 className="paginationBlogInfo_title" dangerouslySetInnerHTML={{ __html: node.title }} />
                    </AniLink>
                  </div>

                </li>
              ))}
            </ul>

            <div className="paginationArea">
              <ul className="paginationLinks">
                <li className="previousLink">
                  <NavLink test={first} url={`/${"posts" + previousUrl}`} text="PREV"/>
                </li>
                <li className="nextLink">
                  <NavLink test={last} url={`/${"posts/" + nextUrl}`} text="NEXT"/>
                </li>
              </ul>
              <span className="paginationArea_count">{index} of {pageCount} Pages</span>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default Pagination